exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-templates-creating-applications-js": () => import("./../../../src/templates/creatingApplications.js" /* webpackChunkName: "component---src-templates-creating-applications-js" */),
  "component---src-templates-our-work-template-js": () => import("./../../../src/templates/ourWorkTemplate.js" /* webpackChunkName: "component---src-templates-our-work-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-software-house-js": () => import("./../../../src/templates/softwareHouse.js" /* webpackChunkName: "component---src-templates-software-house-js" */)
}

